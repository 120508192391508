<template>
  <div class="profile">
    <v-row>
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <MenuNavigator class="menu-navigator" />
      </v-col>

      <v-col cols="12" sm="12" md="9">
        <ProfileTitle class="mb-5 px-0" :category="category" />
        <v-form
          ref="userDataForm"
          class="pl-3"
          v-model="isFormValid"
          v-if="userData"
        >
          <v-row>
            <v-col cols="12" sm="6" lg="5">
              <!-- nome -->
              <label v-html="`${$t('profile.firstName')}*`" />
              <v-text-field
                v-model="userData.firstName"
                persistent-placeholder
                :readonly="userDataFormDisabled"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="6" lg="5">
              <!-- cognome -->
              <label v-html="`${$t('profile.lastName')}*`" />
              <v-text-field
                v-model="userData.lastName"
                persistent-placeholder
                :readonly="userDataFormDisabled"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6" lg="5">
              <!-- telefono -->
              <label v-html="`${$t('profile.homePhone')} *`" />
              <v-text-field
                v-model="userData.phone"
                :readonly="userDataFormDisabled"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="5">
              <!-- cellulare -->
              <label v-html="`${$t('profile.email')}`" />
              <v-text-field
                v-model="userData.email"
                :readonly="userDataFormDisabled"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-0 mt-sm-6 button-row">
            <v-col cols="12" sm="4">
              <v-hover v-slot="{ hover }">
                <v-btn
                  large
                  depressed
                  block
                  :class="
                    hover ? 'grey darken-2 white--text' : 'primary lighten-1'
                  "
                  :href="updateLink"
                  target="blank"
                >
                  {{ $t("profile.edit") }}
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import categoryMixin from "~/mixins/category";
import MenuNavigator from "@/components/profile/MenuNavigator";
import UserService from "~/service/userService";
import ProfileTitle from "@/components/profile/ProfileTitle";

export default {
  components: {
    MenuNavigator,
    ProfileTitle
  },
  mixins: [categoryMixin],
  data() {
    return {
      userData: null,
      isFormValid: false,
      userDataFormDisabled: true
    };
  },
  computed: {
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    },
    updateLink: function() {
      return window.location.host === "laspesaonlinetest.eurospin.it" ||
        window.location.host === "laspesaonlinedev.eurospin.it"
        ? "https://preprod-identity.eurospin.it/"
        : "https://identity.eurospin.it/s/";
    }
  },
  methods: {
    async editUserData() {
      let that = this;
      try {
        this.loading = true;
        let res = await UserService.updateUserDetail(that.userData);
        this.loading = false;
        if (res && !res.errors) {
          global.EventBus.$emit("success", {
            message: global.vm.$t("message.userDetailsUpdated")
          });
        } else {
          if (res.errors) {
            that.error = true;
          }
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    let that = this;
    try {
      this.loading = true;
      let res = await UserService.getUserDetail();
      this.loading = false;
      if (res && !res.errors) {
        this.userData = res;
      } else {
        if (res.errors) {
          that.error = true;
        }
      }
    } catch (response) {
      this.response = response;
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.profile {
  [class*=" col-"] {
    padding-bottom: 0px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding-top: 0px;
    }
  }
  .v-input--selection-controls {
    margin-top: 5px;
  }
  label {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
  }
  ::v-deep .v-text-field__slot {
    font-size: 12px;
  }
  .button-row {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      position: fixed;
      bottom: 0px;
      left: 0px;
      z-index: 9;
      width: 100%;
      padding: 5px;
      background: var(--v-primary-base);
    }
  }
}
</style>
